import axios from 'axios'; 


export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {

        getTeam(_,_params){
            return new Promise((resolve, reject) => {
                axios.get('team', {params : _params}).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            })
        },

        getTeamByKecamatan(_,_params){
            return new Promise((resolve, reject) => {
                axios.post('team/team-by-kecamatan', {regions : _params}).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            })
        },
        
        addTeam(_, data){
            return new Promise((resolve,reject) => {
                axios.post('team/create/', data).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e);
                })
            })
        },

        updateTeam(_, {id,data}){
            return new Promise((resolve,reject) => {
                axios.put('team/update/'+ id, data).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        deleteTeam(_, id){
            return new Promise((resolve,reject) => {
                axios.delete('team/delete/'+ id).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

    },
    getters: {

    }

}