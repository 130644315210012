import axios from 'axios'; 


export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {

        getReportPengaduan(_,_params){
            return new Promise((resolve, reject) => {
                axios.get('report/laporanComplaint', {params : _params}).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            })
        },

        getReportTeamItemUsage(_,_params){
            return new Promise((resolve, reject) => {
                axios.get('report/laporanItemUsageTeam', {params : _params}).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            })
        },

        getReportPerYear(_,_params){
            return new Promise((resolve, reject) => {
                axios.get('report/laporanKegiatanPerTahun', {params : _params}).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            })
        },

        getReportTeamItemUsagePerYear(_,_params){
            return new Promise((resolve, reject) => {
                axios.get('report/laporanItemUsage', {params : _params}).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            })
        },

        getReportPemeliharaan(_,_params){
            return new Promise((resolve, reject) => {
                axios.get('report/laporanKegiatan', {params : _params}).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            })
        },

        getReportPengaduanExcel(_,_params){
            return new Promise((resolve, reject) => {
                axios.get('report/laporanComplaintExcel', {params : _params}).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            })
        },
    },
    getters: {

    }

}