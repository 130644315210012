import axios from 'axios'; 


export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {

        getItem(_,_params){
            return new Promise((resolve, reject) => {
                axios.get('item', {params : _params}).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            })
        },
        
        addItem(_, data){
            return new Promise((resolve,reject) => {
                axios.post('item/create/', data).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e);
                })
            })
        },

        updateItem(_, {id,data}){
            return new Promise((resolve,reject) => {
                axios.put('item/update/'+ id, data).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        deleteItem(_, id){
            return new Promise((resolve,reject) => {
                axios.delete('item/delete/'+ id).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },
    },
    getters: {

    }

}