 import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views - menu
const Dashboard = () => import('@/views/menu/dashboard')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const User = () => import('@/views/menu/User')
const Team = () => import('@/views/menu/Team')
const Item = () => import('@/views/menu/Item')
const Kecamatan = () => import('@/views/menu/Kecamatan')
const Masuk = () => import('@/views/menu/Item-Masuk')
const Keluar = () => import('@/views/menu/Item-Keluar')
const Terpakai = () => import('@/views/menu/Item-Terpakai')
const Pengaduan = () => import('@/views/menu/Item-Pengaduan')
const Penugasan = () => import('@/views/menu/Item-Penugasan')
const Schedule = () => import('@/views/menu/Schedule')
const Catins = () => import('@/views/menu/Catins')
const Role = () => import('@/views/menu/Role')
const Menu = () => import('@/views/menu/Menu')
const PerangkatDaerah = () => import('@/views/menu/PerangkatDaerah')
const OnlineChat = () => import('@/views/menu/online-chat')
import Blank from '@/layouts/blank.vue';
const SuratTugasPDF = () => import('@/views/menu/components/surat-tugas-pdf')
const LaporanPemeliharaanPDF = () => import('@/views/menu/components/rekapitulasiLaporanPemeliharaan-pdf')
const LaporanTeamItemUsagePDF = () => import('@/views/menu/components/rekapitulasiLaporanTeamItemUsage-pdf')
const FormItemOutPDF = () => import('@/views/menu/components/form-pengambilan-barang-pdf')
const LaporanPemeliharaanPjuPDF = () => import('@/views/menu/components/laporan-pemeliharaan-pju-pdf')
const LaporanPjuTahunanPDF = () => import('@/views/menu/components/pemeliharaan-pju-tahunnan-pdf')
const LaporanItemUsageTeamPDF = () => import('@/views/menu/components/laporan-item-usage-team-pdf')
const RekapitulasiKegiatanPemeliharaanPDF = () => import('@/views/menu/components/rekapitulasiKegiatanPemeliharaan-pdf')
const PengaduanPDF = () => import('@/views/menu/components/pengaduan-pdf')
const Report = () => import('@/views/menu/Report')

Vue.use(Router)

export default new Router({
  mode: 'hash',
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes() {
  return [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: 'User',
          name: 'Master User',
          component: User
        },
        {
          path: 'master-team',
          name: 'Master Team',
          component: Team
        },
        {
          path: 'master-item',
          name: 'Master Item',
          component: Item
        },
        {
          path: 'master-kecamatan',
          name: 'Master Kecamatan',
          component: Kecamatan
        },
        {
          path: 'transaksi-item-masuk',
          name: 'Item Masuk',
          component: Masuk
        },
        {
          path: 'transaksi-item-keluar',
          name: 'Item Keluar',
          component: Keluar
        },
        {
          path: 'transaksi-item-terpakai',
          name: 'Item Terpakai',
          component: Terpakai
        },
        {
          path: 'transaksi-item-pengaduan',
          name: 'Pengaduan',
          component: Pengaduan
        },
        {
          path: 'transaksi-item-penugasan',
          name: 'Penugasan',
          component: Penugasan
        },
        {
          path: 'Schedule',
          name: 'Kelola Schedule',
          component: Schedule
        },
        {
          path: 'Catins',
          name: 'Kelola Catins',
          component: Catins
        },
        {
          path: 'Role',
          name: 'Kelola Role / PD',
          component: Role
        },
        {
          path: 'Menu',
          name: 'Kelola Menu',
          component: Menu
        },
        {
          path: 'PerangkatDaerah',
          name: 'Kelola Perangkat Daerah',
          component: PerangkatDaerah
        },
        {
          path: 'online-chat',
          name: 'Online Chat',
          component: OnlineChat
        },
        {
          path: 'Menu-Report',
          name: 'Menu Report',
          component: Report
        },
      ]
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render(c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
      ]
    },
    {
      path: '/',
      component: Blank,
      redirect: '/surat-tugas-pdf',
      children: [
        {
          path: '/surat-tugas-pdf/:id',
          name: 'surat-tugas-pdf',
          component: SuratTugasPDF
        },
        {
          path: '/laporan-pemeliharran-pdf',
          name: 'laporan-pemeliharran-pdf',
          component: LaporanPemeliharaanPDF
        },
        {
          path: '/laporan-team-item-usage-pdf',
          name: 'laporan-team-item-usage-pdf',
          component: LaporanTeamItemUsagePDF
        },
        {
          path: '/form-pengambilan-barang-pdf/:id',
          name: 'form-pengambilan-barang-pdf',
          component: FormItemOutPDF
        },
        {
          path: '/laporan-pemeliharran-pju-pdf/:id',
          name: 'laporan-pemeliharran-pju-pdf',
          component: LaporanPemeliharaanPjuPDF
        },
        {
          path: '/pemeliharaan-pju-tahunan-pdf',
          name: 'pemeliharaan-pju-tahunan-pdf',
          component: LaporanPjuTahunanPDF
        },
        {
          path: '/laporan-item-usage-team-pdf',
          name: 'laporan-item-usage-team-pdf',
          component: LaporanItemUsageTeamPDF
        },
        {
          path: '/rekap-kegiatan-pemeliharran-pdf',
          name: 'rekap-kegiatan-pemeliharran-pdf',
          component: RekapitulasiKegiatanPemeliharaanPDF
        },
        {
          path: '/pengaduan-pdf',
          name: 'pengaduan-pdf',
          component: PengaduanPDF
        },
      ],
  
      meta: {
        requireAuth: true,
      },
    }
  ]
}

