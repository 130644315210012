import Vue from 'vue'
import Vuex from 'vuex'
import auth from './module/auth'
import user from './module/user'
import schedule from './module/schedule'
import catins from './module/catins'
import menu from './module/menu'
import role from './module/role'
import dashboard from './module/dashboard'
import perangkat_daerah from './module/perangkat_daerah'
import room from './module/room'
import item from './module/item'
import team from './module/team'
import kecamatan from './module/kecamatan'
import receive from './module/item-receive'
import out from './module/item-out'
import usage from './module/item-usage'
import complaint from './module/item-complaint'
import assignment from './module/item-assignment'
import report from './module/report'

Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false
}

const mutations = {
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set(state, [variable, value]) {
    state[variable] = value
  }
}

export default new Vuex.Store({
  state,
  modules: {
    auth,
    user,
    schedule,
    catins,
    dashboard,
    menu,
    item,
    team,
    role,
    kecamatan,
    receive,
    out,
    usage,
    complaint,
    assignment,
    perangkat_daerah,
    room,
    report
  },
  mutations
})