<template >
<div class="white">
    <router-view></router-view>
</div>
</template>

<script>
export default {

};
</script>


<style scoped>
.white {
    /* width:100%;
    height:100%; */
    background-color: white !important;
    /* color : white; */
    /* background-image: url('../assets/images/bg.png'); */
    /* background-size: 90%; */
    /* background-repeat: no-repeat; */
    /* background-position: bottom; */

}
</style>
